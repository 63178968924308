<template>
    <!-- Router view -->
    <div class="w-screen h-screen flex items-center justify-center bg-gray-100">
        <div class="max-w-xl w-full">
            <!-- Header -->
            <div class="flex justify-between w-full">
                <div>
                    <img :src="logo" alt="Logo Schola Pragensis" class="h-[35px]">
                </div>
                <div></div>
            </div>
            <!-- Router outlet -->
            <div class="mt-6 bg-white rounded-md p-8 border">
                <router-view/>
            </div>
            <!-- Footer -->
            <div class="mt-4 border-t w-full text-center">
                <p class="text-gray-400 mt-4 text-sm">V případě dotazů či nejasností prosím neváhejte kontaktovat <a class="text-primary hover:underline" href="mailto:veletrh@scholapragensis.online">veletrh@scholapragensis.online</a></p>
            </div>
        </div>
    </div>
</template>

<script>
    import logo from './assets/logo.png';

    export default {
        name: 'App',
        data() {
            return {
                logo
            }
        }
    }
</script>