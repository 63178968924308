<template>
    <h1 class="text-3xl font-semibold leading-[1.2em]">Vítejte v rezervačním<br>systému Schola Pragensis</h1>
    <p class="mt-4 text-gray-500 text-justify">Cílem tohoto systému je zamezit velkým frontám na fyzickém veletrhu <a class="text-primary hover:underline" href="https://www.scholapragensis.online">Schola Pragensis</a> a zajistit tak všem návštěvníkům skvělý zážitek. Tlačítkem níže prosím vstupte do rezervačního systému a vyberte si datum a čas Vaší návštěvy.</p>
    <br>
    <p class="mt-4 text-gray-500 text-justify"><strong>Upozornění:</strong> Použití rezervačního nástroje je dobrovolné a slouží toliko k rozložení náporu účastníků na veletrhu. Změnu termínu a počtu účastníků či zrušení účasti není třeba ohlašovat. Při vstupu na veletrh se není třeba rezervací prokazovat. Rezervační nástroj nezasílá potvrzení o rezervaci e-mailem.</p>
    
    <router-link to="/reservation">
        <button type="button" class="mt-8 flex justify-center items-center gap-2 w-full rounded-md bg-primary px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary-dark focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary">
            Vstoupit
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                <path fill-rule="evenodd" d="M16.72 7.72a.75.75 0 011.06 0l3.75 3.75a.75.75 0 010 1.06l-3.75 3.75a.75.75 0 11-1.06-1.06l2.47-2.47H3a.75.75 0 010-1.5h16.19l-2.47-2.47a.75.75 0 010-1.06z" clip-rule="evenodd" />
            </svg>
        </button>
    </router-link>
</template>

<script>
export default {
    name: 'HomeView',
    beforeMount() {
        // Máme už vyplněno?
        if (localStorage.getItem('done')) {
            this.$router.push('/end');
        }
    }
}
</script>
